var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"text-center",attrs:{"id":"header"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"text-center mb-3 mt-4",attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"alt":"Maria Scare Logo","src":require('@/assets/images/logo-merged.svg'),"width":"500","aspect-ratio":1129 / 375}})],1)],1)],1),_c('div',{staticClass:"d-flex d-sm-none justify-center",attrs:{"id":"menu2"}},[_c('v-btn',{staticClass:"mx-2 text-uppercase font-weight-bold menu-btn justify-center",staticStyle:{"z-index":"10"},attrs:{"outlined":"","rounded":"","color":"warning","small":"","width":"105","height":"22"},on:{"click":function($event){return _vm.navbarToggle()}}},[_c('div',{staticClass:"menu-btn__burger text-center ma-3"}),(false && _vm.navbarMenuVisible)?_c('div',{staticClass:"pt-15 white--text text-center"},[_vm._v(" Ahoj ")]):_vm._e()])],1),(_vm.navbarMenuVisible)?_c('div',{staticClass:"clearfix"},_vm._l((_vm.data.content),function(item,i){return _c('router-link',{key:i,class:[
        'menu',
        'text-decoration-none',
        {
          'router-link-active':
            _vm.$route.params.slug === undefined && item.slug['cs'] === _vm.initSlug,
        } ],attrs:{"to":'/' + item.slug['cs']}},[_c('v-btn',{staticClass:"mx-2 text-uppercase font-weight-bold",staticStyle:{"z-index":"10"},attrs:{"outlined":"","rounded":"","color":"warning","small":"","width":"105","height":"22"},on:{"click":function($event){return _vm.navbarMenuClose()}}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.title))])])],1)}),1):_vm._e(),_c('div',{staticClass:"d-none d-sm-flex justify-center",attrs:{"id":"menu"}},_vm._l((_vm.data.content),function(item,i){return _c('router-link',{key:i,class:[
        'menu',
        'text-decoration-none',
        {
          'router-link-active':
            _vm.$route.params.slug === undefined && item.slug['cs'] === _vm.initSlug,
        } ],attrs:{"to":'/' + item.slug['cs']}},[_c('v-btn',{staticClass:"mx-2 text-uppercase font-weight-bold",staticStyle:{"z-index":"10"},attrs:{"outlined":"","rounded":"","color":"warning","small":"","width":"105","height":"22"},on:{"click":function($event){return _vm.navbarMenuClose()}}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.title))])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container id="header" class="text-center">
    <v-row class="justify-center">
      <v-col cols="12" class="text-center mb-3 mt-4">
        <router-link to="/"
          ><v-img
            class="mx-auto"
            alt="Maria Scare Logo"
            :src="require('@/assets/images/logo-merged.svg')"
            width="500"
            :aspect-ratio="1129 / 375"
        /></router-link>
      </v-col>
    </v-row>
    <div id="menu2" class="d-flex d-sm-none justify-center">
      <v-btn
        outlined
        rounded
        color="warning"
        style="z-index: 10"
        small
        width="105"
        height="22"
        class="mx-2 text-uppercase font-weight-bold menu-btn justify-center"
        @click="navbarToggle()"
      >
        <div class="menu-btn__burger text-center ma-3" />
        <!-- <v-icon class="white--text">mdi-menu</v-icon> -->
        <div
          class="pt-15 white--text text-center"
          v-if="false && navbarMenuVisible"
        >
          Ahoj
        </div>
      </v-btn>
    </div>
    <div class="clearfix" v-if="navbarMenuVisible">
      <router-link
        :class="[
          'menu',
          'text-decoration-none',
          {
            'router-link-active':
              $route.params.slug === undefined && item.slug['cs'] === initSlug,
          },
        ]"
        v-for="(item, i) in data.content"
        v-bind:key="i"
        :to="'/' + item.slug['cs']"
        ><v-btn
          outlined
          rounded
          color="warning"
          style="z-index: 10"
          small
          width="105"
          height="22"
          class="mx-2 text-uppercase font-weight-bold"
          @click="navbarMenuClose()"
          ><span class="white--text">{{ item.title }}</span></v-btn
        ></router-link
      >
    </div>
    <!-- <v-btn rounded color="warning" style='z-index:10; background-color: #000;' small width=100 height=22 class="mx-3 text-uppercase font-weight-bold"
        >O Nás</v-btn
      >
      <router-link :to="{ name: 'Koncerty', params: { 'lang':'cs', 'slug':'koncerty'} }"><v-btn outlined rounded color="warning" style='z-index:10; background-color: #000;' small width=100 height=22 class="mx-2 text-uppercase font-weight-bold"><span class="white--text">Koncerty</span></v-btn></router-link>
      <router-link :to="{}"><v-btn outlined rounded color="warning" style='z-index:10; background-color: #000;' small width=100 height=22 class="mx-2 text-uppercase font-weight-bold"><span class="white--text">Diskografie</span></v-btn></router-link>
      <router-link :to="{}"><v-btn outlined rounded color="warning" style='z-index:10; background-color: #000;' small width=100 height=22 class="mx-2 text-uppercase font-weight-bold"><span class="white--text">Galerie</span></v-btn></router-link> -->
    <!-- <router-link
        :class="[
          'menu',
          'text-decoration-none',
          {
            'router-link-active':
              $route.params.slug === undefined && item.slug['cs'] === initSlug,
          },
        ]"
        v-for="(item, i) in data.content"
        v-bind:key="i"
        :to="'/' + item.slug['cs']"
        ><v-btn
          outlined
          rounded
          color="warning"
          style="z-index: 10"
          small
          width="100"
          height="22"
          class="mx-2 text-uppercase font-weight-bold"
          ><span class="white--text">{{ item.title }}</span></v-btn
        ></router-link
      > -->
    <!-- </div> -->
    <div id="menu" class="d-none d-sm-flex justify-center">
      <!-- <v-btn rounded color="warning" style='z-index:10; background-color: #000;' small width=100 height=22 class="mx-3 text-uppercase font-weight-bold"
        >O Nás</v-btn
      >
      <router-link :to="{ name: 'Koncerty', params: { 'lang':'cs', 'slug':'koncerty'} }"><v-btn outlined rounded color="warning" style='z-index:10; background-color: #000;' small width=100 height=22 class="mx-2 text-uppercase font-weight-bold"><span class="white--text">Koncerty</span></v-btn></router-link>
      <router-link :to="{}"><v-btn outlined rounded color="warning" style='z-index:10; background-color: #000;' small width=100 height=22 class="mx-2 text-uppercase font-weight-bold"><span class="white--text">Diskografie</span></v-btn></router-link>
      <router-link :to="{}"><v-btn outlined rounded color="warning" style='z-index:10; background-color: #000;' small width=100 height=22 class="mx-2 text-uppercase font-weight-bold"><span class="white--text">Galerie</span></v-btn></router-link> -->
      <router-link
        :class="[
          'menu',
          'text-decoration-none',
          {
            'router-link-active':
              $route.params.slug === undefined && item.slug['cs'] === initSlug,
          },
        ]"
        v-for="(item, i) in data.content"
        v-bind:key="i"
        :to="'/' + item.slug['cs']"
        ><v-btn
          outlined
          rounded
          color="warning"
          style="z-index: 10"
          small
          width="105"
          height="22"
          class="mx-2 text-uppercase font-weight-bold"
          @click="navbarMenuClose()"
          ><span class="white--text">{{ item.title }}</span></v-btn
        ></router-link
      >
    </div>
  </v-container>
</template>

<script>
import "./Menu.scss";

export default {
  name: "Menu",
  props: {
    data: Object,
    initSlug: String,
  },
  data() {
    return {
      navbarMenuVisible: 0,
    };
  },
  methods: {
    log(msg) {
      console.log(msg);
    },
    navbarMenuClose() {
      if (this.navbarMenuVisible === 1) {
        this.navbarMenuVisible = 0;
        // document.querySelector("html").classList.remove("nav-open");
        // document.querySelector("#bodyClick").remove();
        // document.querySelector(".navbar-brand").classList.remove("moved");
        document.querySelector(".menu-btn").classList.remove("open");
      }
    },
    navbarMenuOpen() {
      this.navbarMenuVisible = 1;
      // document.querySelector("html").classList.add("nav-open");
      // document.querySelector(".navbar-brand").classList.add("moved");
      // document.querySelector('.navbar-brand').animate({'margin-left': '235px'}, 300);
      document.querySelector(".menu-btn").classList.add("open");

      const div = document.createElement("div");
      div.id = "bodyClick";
      document.querySelector("body").appendChild(div);
      document
        .querySelector("#bodyClick")
        .addEventListener("click", this.navbarMenuClose);
    },
    navbarToggle() {
      if (this.navbarMenuVisible === 0) {
        this.navbarMenuOpen();
      } else {
        this.navbarMenuClose();
      }
    },
  },
};
</script>
<style lang="scss">
#header {
  a.menu {
    button {
      background-color: #000;
      // border:
      border-color: #f25a26;
      span {
        text-decoration: none;
      }
    }
  }
  a.menu.router-link-active {
    button {
      background-color: #f25a26;
      border-color: #000;
      text-decoration: none;
    }
  }

  .menu-btn {
    text-align: center;
    background-color: #000;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    // min-height: 27px;
    /* border: 3px solid #fff; */
  }
  .menu-btn__burger {
    width: 21px;
    height: 2px;
    background: #fff;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
  }
  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: "";
    position: absolute;
    margin-left: -11px;
    width: 21px;
    height: 2px;
    background: #fff;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
  }
  .menu-btn__burger::before {
    transform: translateY(-6px);
  }
  .menu-btn__burger::after {
    transform: translateY(6px);
  }
  /* ANIMATION */
  .menu-btn.open .menu-btn__burger {
    // transform: translateX(-20px);
    background: transparent;
    // box-shadow: none;
  }
  .menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(0, 0);
  }
  .menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(0, 0);
  }
}
</style>

<template v-if="data">
  <div
    :class="[
      {
        'd-flex justify-center flex-wrap': data.type == 'contact',
      },
    ]"
  >
    <TextContent
      v-if="data.schemaName == 'text'"
      :data="data"
      :perexLength="perexLength"
    />
    <ImageContent v-if="data.schemaName == 'image'" :data="data" />

    <template v-if="data.schemaName == 'gallery'">
      <ImageContent v-if="data.type == 'carousel'" :data="data" />
      <Gallery v-else :data="data" />
    </template>

    <!-- <Product v-else-if="data.schemaName == 'product'" :data="data" /> -->
    <Contact v-else-if="data.schemaName == 'contact'" :data="data" />

    <Concert v-else-if="data.schemaName == 'concerts'" :data="data" />

    <template v-else-if="data.schemaName == 'group'">
      <Albums v-if="data.type == 'album'" :data="data" />
      <General
        v-for="(item, j) in data.content"
        v-bind:key="j"
        :data="item"
        :perexLength="data.perexLength"

      />
    </template>
  </div>
</template>

<script>
import General from "./General.vue";
import TextContent from "../Text/TextContent.vue";
import ImageContent from "../Image";
import Gallery from "../Gallery/Gallery.vue";
import Contact from "../Contact/Contact.vue";
import Concert from "../Concert/Concert.vue";
// import Product from "../Product/Product.vue";
import Albums from "../Albums/Albums.vue";

import "./General.scss";

export default {
  name: "General",
  components: {
    TextContent,
    ImageContent,
    Gallery,
    General,
    // Product,
    Contact,
    Concert,
    Albums,
  },
  data() {
    return {};
  },
  props: {
    data: Object,
    perexLength: Number,
  },
  mounted() {
    // console.log(this.data.schemaName);
    // console.log(this.data);
  },
  methods: {
    log(msg) {
      console.log(msg);
    },
  },
};
</script>
